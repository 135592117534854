import { apiUrl } from '../../constants';
import User from '../../types/user';

let authenticatedUserPromise: Promise<User | null> | null = null;
const loginsByCode: { [code: string]: Promise<User> } = {};

// Gets the currently authenticated user.
const getAuthenticatedUser = (): Promise<User | null> => {
  return (
    authenticatedUserPromise ||
    (authenticatedUserPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${apiUrl}/v1/user`, {
          credentials: 'include',
        });

        switch (response.status) {
          case 200:
            const result = await response.json();
            resolve(result);
            return;
          case 401:
            resolve(null);
            return;
          default:
            throw new Error(
              'Failed to determined authenticated user, please refresh the page to try again.'
            );
        }
      } catch (e) {
        reject(e);
      }
    }))
  );
};

// Logs in the user with their authorization code.
const login = (code: string): Promise<User> => {
  const loginPromise = loginsByCode[code];
  if (loginPromise) {
    return loginPromise;
  }

  return (loginsByCode[code] = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${apiUrl}/v1/login?code=${encodeURIComponent(code)}`,
        {
          method: 'POST',
          credentials: 'include',
        }
      );

      if (response.status === 200) {
        var result = await response.json();
        authenticatedUserPromise = Promise.resolve(result);
        resolve(result);
        return;
      }

      throw new Error('Login failed, please try again.');
    } catch (e) {
      reject(e);
    }
  }));
};

// Logs out the authenticated user.
const logout = async (): Promise<void> => {
  const response = await fetch(`${apiUrl}/v1/logout`, {
    method: 'POST',
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Logout failed, please try again.');
  }
};

export { getAuthenticatedUser, login, logout };
