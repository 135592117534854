import { del, get, keys, set } from 'idb-keyval';
import Episode from '../../types/episode';
import SavedEpisode from '../../types/saved-episode';
import Show from '../../types/show';

// Artwork data URI cache
const artworkPromises: { [url: string]: Promise<string> } = {};

// Audio data URI cache
const audioPromises: { [url: string]: Promise<string> } = {};

let downloadingValue = '';

const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result as string);
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(blob);
  });
};

// Sends the request to download artwork for a show.
const downloadArtworkUrl = async (url: string): Promise<string> => {
  const artwork = await fetch(url);
  const blob = await artwork.blob();
  const dataUri = await blobToDataURL(blob);
  return dataUri;
};

// Fetches the artwork data URI for a show.
const downloadArtwork = async (show: Show): Promise<string> => {
  const url = show.artwork[0].src;
  if (!artworkPromises[url]) {
    artworkPromises[url] = downloadArtworkUrl(url);
  }
  return artworkPromises[url];
};

// Sends the request to download artwork for a show.
const downloadAudioUrl = async (url: string): Promise<string> => {
  const audio = await fetch(url);
  const blob = await audio.blob();
  const dataUri = await blobToDataURL(blob);
  return dataUri;
};

// Fetches the artwork data URI for a show.
const downloadAudio = async (episode: Episode): Promise<string> => {
  const url = episode.audio;
  if (!audioPromises[url]) {
    audioPromises[url] = downloadAudioUrl(url);
  }
  return audioPromises[url];
};

// Fetches the IDs of all of the stored episodes.
const getEpisodeIds = async (): Promise<string[]> => {
  const episodes = await keys();
  return episodes.map((e) => e.toString());
};

// Updates episode progress.
const saveEpisodeProgress = async (
  episodeId: string,
  progress: number
): Promise<void> => {
  const progressKey = `duration:${episodeId}`;
  if (progress) {
    window.localStorage.setItem(progressKey, `${Math.floor(progress)}`);
  } else {
    window.localStorage.removeItem(progressKey);
  }
};

// Deletes a local episode.
const deleteEpisode = async (episodeId: string): Promise<void> => {
  await del(episodeId);
};

// Fetches an episode from the database.
const getEpisode = async (episodeId: string): Promise<Episode | undefined> => {
  const episode = await get<SavedEpisode>(episodeId);
  if (!episode) {
    return;
  }

  const progressKey = `duration:${episodeId}`;
  const audio = await fetch(episode.audio);
  const audioBlob = await audio.blob();
  episode.audio = '';

  return {
    id: episode.id,
    name: episode.name,
    description: episode.description,
    progress: Number(window.localStorage.getItem(progressKey)) || 0,
    audio: URL.createObjectURL(audioBlob),
    created: episode.created,
    duration: episode.duration,
    show: {
      id: episode.showId,
      name: episode.showName,
      artwork: [
        {
          src: episode.image,
        },
      ],
    },
    saveProgress(progress) {
      if (progress) {
        saveEpisodeProgress(episodeId, Math.floor(progress));
      } else {
        deleteEpisode(episode.id);
      }
    },
  };
};

// Downloads an episode locally.
const saveEpisode = async (episode: Episode): Promise<void> => {
  downloadingValue = `${episode.created.toLocaleDateString()}: ${episode.name}`;

  try {
    const artwork = await downloadArtwork(episode.show);
    const audio = await downloadAudio(episode);
    const row: SavedEpisode = {
      id: episode.id,
      name: episode.name,
      description: episode.description,
      created: episode.created,
      audio: audio,
      showName: episode.show.name,
      showId: episode.show.id,
      image: artwork,
      duration: episode.duration,
    };

    await set(episode.id, row);
  } finally {
    downloadingValue = '';
  }
};

const getDownloadingEpisode = (): string => {
  return downloadingValue;
};

export {
  deleteEpisode,
  getDownloadingEpisode,
  getEpisode,
  getEpisodeIds,
  saveEpisode,
  saveEpisodeProgress,
};
