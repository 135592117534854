import { Cast, CastConnected, Error } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import Episode from '../types/episode';

const init = () => {
  // eslint-disable-next-line no-restricted-globals
  if (location.href.includes('localhost')) {
    return undefined;
  }

  // @ts-ignore:next-line
  if (!window.cjs) {
    try {
      // @ts-ignore:next-line
      window.cjs = new Castjs();
    } catch (e) {
      // Failed to init cast API
      console.error('Failed to init cast API', e);
    }
  }

  // @ts-ignore:next-line
  return window.cjs;
};

const cjs = init();

type CastButtonProperties = {
  // The time to start the audio at, when it plays.
  episode: Episode;

  // The ref to store the audio in.
  audioRef: React.RefObject<HTMLAudioElement>;
};

export default function CastButton({
  episode,
  audioRef,
}: CastButtonProperties) {
  const [available, setAvailable] = useState(false);
  const [connected, setConnected] = useState(false);
  const [errored, setErrored] = useState(false);

  const connect = () => {
    if (!episode.audio || !cjs.cast) {
      return;
    }

    cjs.cast(episode.audio, {
      poster: episode.show.artwork,
      title: episode.name,
      description: episode.show.name,
      time: audioRef.current ? audioRef.current.currentTime : episode.progress,
      paused: audioRef.current ? audioRef.current.paused : false,
    });
  };

  const disconnect = () => {
    cjs.disconnect();
  };

  useEffect(() => {
    try {
      // Casting is available
      cjs.on('available', () => {
        setAvailable(cjs.available);
      });

      // Connected with device
      cjs.on('connect', () => {
        if (audioRef.current) {
          audioRef.current.volume = 0;
        }

        setConnected(true);
      });

      // Disconnected with device
      cjs.on('disconnect', () => {
        if (audioRef.current) {
          audioRef.current.volume = 1;
        }

        setConnected(false);
      });

      // Device state
      cjs.on('statechange', () => {
        console.log('state change', cjs.state);
      });

      // Current time changed
      cjs.on('timeupdate', () => {
        if (
          audioRef.current &&
          cjs.time &&
          Math.abs(cjs.time - audioRef.current.currentTime) > 5
        ) {
          audioRef.current.currentTime = cjs.time;
        }
      });

      // Media is playing
      cjs.on('playing', () => {
        console.log('casting playing');

        if (audioRef.current) {
          // Make sure local audio is "playing" when casting is.
          audioRef.current.volume = 0;
          audioRef.current.play();
        }
      });

      // Media is paused
      cjs.on('pause', () => {
        console.log('casting paused');
        if (audioRef.current) {
          // Make sure local audio is paused when casting is.
          audioRef.current.pause();
        }
      });

      // Media ended
      cjs.on('end', () => {
        // When the media ends, for now.. just disconnect.
        cjs.disconnect();
      });

      // Catch any errors
      cjs.on('error', (err: any) => {
        console.error(err);
        setErrored(err);
      });
    } catch (e) {
      console.error('Failed to connect cast API', e);
    }

    return () => {
      cjs.off();
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    const pause = () => {
      cjs.pause();
    };

    const play = () => {
      cjs.play();
    };

    const seek = () => {
      cjs.seek(audio.currentTime);
    };

    audio.addEventListener('pause', pause);
    audio.addEventListener('play', play);
    audio.addEventListener('seeked', seek);

    return () => {
      audio.removeEventListener('pause', pause);
      audio.removeEventListener('play', play);
      audio.removeEventListener('seeked', seek);
    };
  }, [audioRef]);

  if (!available) {
    return <Fragment />;
  }

  if (errored) {
    return <Error />;
  }

  if (connected) {
    return (
      <IconButton
        edge="end"
        onClick={disconnect}
        disableFocusRipple
        disableRipple
      >
        <CastConnected />
      </IconButton>
    );
  }

  return (
    <IconButton edge="end" onClick={connect} disableFocusRipple disableRipple>
      <Cast />
    </IconButton>
  );
}

export type { CastButtonProperties };
