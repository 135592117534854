import { Forward30, Replay30 } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Fragment, useEffect } from 'react';
import Episode from '../types/episode';
import CastButton from './cast-button';

type EpisodePlaybackProperties = {
  // The time to start the audio at, when it plays.
  episode: Episode;

  // The ref to store the audio in.
  audioRef: React.RefObject<HTMLAudioElement>;
};

export default function EpisodePlayback({
  episode,
  audioRef,
}: EpisodePlaybackProperties) {
  /*
  const [volume, setVolume] = useState(
    window.localStorage ? Number(window.localStorage.getItem('volume')) || 1 : 1
  );
  */

  const rewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 30;
    }
  };

  const skipAhead = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += Math.min(30, audioRef.current.duration);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    const durationChange = () => {
      audio.currentTime = episode.progress;
      audio.play();
    };

    const playing = () => {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: episode.name,
          artist: episode.show.name,
          artwork: episode.show.artwork,
        });
      }
    };

    const paused = () => {
      episode.saveProgress(audio.currentTime);
    };

    const ended = () => {
      episode.saveProgress();
    };

    /*
    const volumeChanged = () => {
      if (window.localStorage) {
        window.localStorage.setItem('volume', `${audio.volume}`);
        setVolume(audio.volume);
      }
    };
    */

    audio.addEventListener('durationchange', durationChange);
    audio.addEventListener('playing', playing);
    audio.addEventListener('pause', paused);
    audio.addEventListener('ended', ended);
    //audio.addEventListener('volumechange', volumeChanged);

    if (audio.duration <= 0) {
      audio.load();
    }

    return () => {
      audio.removeEventListener('durationchange', durationChange);
      audio.removeEventListener('playing', playing);
      audio.removeEventListener('pause', paused);
      audio.removeEventListener('ended', ended);
      //audio.removeEventListener('volumechange', volumeChanged);
    };
  }, [
    audioRef,
    episode.audio,
    episode.progress,
    episode.name,
    episode.show.name,
    episode.show.artwork,
    episode,
  ]);

  /*
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [audioRef, volume]);
  */

  return (
    <Fragment>
      <IconButton onClick={rewind} disableFocusRipple disableRipple>
        <Replay30 />
      </IconButton>
      <audio
        ref={audioRef}
        src={episode.audio}
        controls
        controlsList="nodownload noplaybackrate"
      />
      <IconButton onClick={skipAhead} disableFocusRipple disableRipple>
        <Forward30 />
      </IconButton>
      <CastButton audioRef={audioRef} episode={episode} />
    </Fragment>
  );
}

export type { EpisodePlaybackProperties };
