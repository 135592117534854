import { Pause, PlayArrow } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import Episode from '../../types/episode';

type PlayButtonProperties = {
  episode: Episode;
  audio: React.RefObject<HTMLAudioElement>;
  currentEpisode: Episode | null;
  play: (episode: Episode) => void;
};

export default function PlayButton({
  episode,
  audio,
  currentEpisode,
  play,
}: PlayButtonProperties) {
  const [paused, setPaused] = useState(true);

  useEffect(() => {
    const audioElement = audio.current;
    if (!audioElement || currentEpisode?.audio !== episode.audio) {
      setPaused(true);
      return;
    }

    setPaused(audioElement.paused);

    const pause = () => {
      setPaused(true);
    };

    const play = () => {
      setPaused(false);
    };

    audioElement.addEventListener('pause', pause);
    audioElement.addEventListener('play', play);

    return () => {
      audioElement.removeEventListener('pause', pause);
      audioElement.removeEventListener('play', play);
    };
  }, [audio, currentEpisode, episode.audio, paused]);

  if (!paused) {
    return (
      <IconButton edge="end" onClick={() => audio.current?.pause()}>
        <Pause />
      </IconButton>
    );
  }

  return (
    <IconButton edge="end" onClick={() => play(episode)}>
      <PlayArrow />
    </IconButton>
  );
}

export type { PlayButtonProperties };
