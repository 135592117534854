import { BottomNavigation, Box, LinearProgress, List } from '@mui/material';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import AudioPlayback from '../../components/audio-playback';
import {
  downloadEpisodes,
  getCurrentEpisode,
  getSavedEpisodes,
} from '../../services/episodes';
import { getDownloadingEpisode } from '../../services/episodes/db';
import Episode from '../../types/episode';
import PageProperties from '../properties';
import EpisodeCard from './episode-card';

export default function Home({ authenticatedUser }: PageProperties) {
  const [downloadingEpisode, setDownloadingEpisode] = useState<string>('');
  const [currentEpisode, setCurrentEpisode] = useState<Episode | null>(null);
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const currentAudio = useRef<HTMLAudioElement>(null);

  const playEpisode = useCallback(
    (episode: Episode) => {
      if (!episode.audio) {
        // Can't play this episode, sadly.
        return;
      }

      const audio = currentAudio.current;
      if (episode.audio === audio?.src) {
        if (audio.paused) {
          // If we're already listening to the desired episode, and it's paused, play it.
          audio.play();
        }

        return;
      }

      if (audio) {
        // If we're already playing an audio, pause it before switching the episode.
        audio.pause();

        // And make sure we save the progress.
        if (currentEpisode?.audio === audio.src) {
          currentEpisode.saveProgress(audio.currentTime);
        }
      }

      setCurrentEpisode(episode);
    },
    [currentEpisode, currentAudio]
  );

  useEffect(() => {
    getCurrentEpisode()
      .then(setCurrentEpisode)
      .catch((err) => {
        console.error('Failed to load the current episode', err);
      });

    getSavedEpisodes()
      .then(setEpisodes)
      .catch((err) => {
        console.error('Failed to load saved episodes', err);
      });

    downloadEpisodes()
      .then(() => {
        console.log('Downloaded latest episodes');
      })
      .catch((err) => {
        console.error('Failed to download saved episodes', err);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const value = getDownloadingEpisode();
      if (downloadingEpisode !== value) {
        setDownloadingEpisode(value);
        getSavedEpisodes()
          .then(setEpisodes)
          .catch((err) => {
            console.error('Failed to load saved episodes', err);
          });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [downloadingEpisode]);

  useEffect(() => {
    const audio = currentAudio.current;
    if (!audio || !currentEpisode) {
      return;
    }

    const ended = () => {
      episodes.forEach((episode, i) => {
        if (episode.audio === audio.src) {
          const nextEpisode = episodes[i + 1];
          if (nextEpisode) {
            playEpisode(nextEpisode);
          }

          return true;
        }
      });
    };

    audio.addEventListener('ended', ended);

    const saveInterval = setInterval(() => {
      if (audio.src === currentEpisode.audio && !audio.paused) {
        // Continuously save the timestamp, if we're not paused.
        currentEpisode.saveProgress(audio.currentTime);
      }
    }, 30_000);

    return () => {
      clearInterval(saveInterval);
      audio.removeEventListener('ended', ended);
    };
  }, [currentAudio, currentEpisode, episodes, playEpisode]);

  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <List>
          {episodes.map((e) => {
            return (
              <EpisodeCard
                key={e.name}
                episode={e}
                audio={currentAudio}
                currentEpisode={currentEpisode}
                play={playEpisode}
              />
            );
          })}
        </List>
      </Box>
      <BottomNavigation
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {currentEpisode && (
          <AudioPlayback audioRef={currentAudio} episode={currentEpisode} />
        )}
        {downloadingEpisode && <LinearProgress title={downloadingEpisode} />}
      </BottomNavigation>
    </Fragment>
  );
}
