import { apiUrl } from '../../constants';
import Episode from '../../types/episode';
import {
  deleteEpisode,
  getEpisode,
  getEpisodeIds,
  saveEpisode,
  saveEpisodeProgress,
} from './db';

let getCurrentEpisodePromise: Promise<Episode | null>;
let getSavedEpisodesPromise: Promise<Episode[]>;

const translateEpisode = (data: any): Episode => {
  const episodeId = data.id;
  const progressKey = `duration:${episodeId}`;
  const episode: Episode = {
    id: episodeId,
    name: data.name,
    description: data.description,
    created: new Date(data.created),
    audio: data.source,
    show: {
      id: data.show.id,
      name: data.show.name,
      artwork: data.show.images
        .sort((a: any, b: any) => {
          return a.width - b.width;
        })
        .map((image: any) => {
          return {
            src: image.url,
            sizes: `${image.width}x${image.height}`,
            type: 'image/png',
          };
        }),
    },
    progress: data.current_timestamp_ms / 1000,
    duration: data.duration_ms,

    saveProgress(progress) {
      if (!window.localStorage) {
        return;
      }

      episode.progress = progress || 0;
      if (progress) {
        window.localStorage.setItem(progressKey, `${Math.floor(progress)}`);
        saveEpisodeProgress(episodeId, Math.floor(progress));
      } else {
        window.localStorage.removeItem(progressKey);
        deleteEpisode(episodeId);
      }
    },
  };

  if (window.localStorage) {
    const progress = Number(window.localStorage.getItem(progressKey));
    if (progress) {
      episode.progress = progress;
    }
  }

  return episode;
};

// Gets the currently playing episode.
const getCurrentEpisode = (): Promise<Episode | null> => {
  return (
    getCurrentEpisodePromise ||
    (getCurrentEpisodePromise = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${apiUrl}/v1/user/currently-playing`, {
          credentials: 'include',
        });
        if (response.status === 204) {
          resolve(null);
          return;
        }

        const result = await response.json();
        resolve(translateEpisode(result));
      } catch (e) {
        reject(e);
      }
    }))
  );
};

// Gets the saved episodes for the authenticated user.
const getSavedEpisodes = (): Promise<Episode[]> => {
  return (
    getSavedEpisodesPromise ||
    (getSavedEpisodesPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${apiUrl}/v1/user/episodes`, {
          credentials: 'include',
        });
        const result = await response.json();
        resolve(result.map(translateEpisode));
      } catch (e) {
        reject(e);
      }
    }))
  );
};

const getStoredEpisodes = async (): Promise<Episode[]> => {
  const existingEpisodeIds = await getEpisodeIds();
  const episodes = await Promise.all(existingEpisodeIds.map(getEpisode));
  const returnedEpisodes: Episode[] = [];
  episodes.forEach((e) => {
    if (e) {
      returnedEpisodes.push(e);
    }
  });
  return returnedEpisodes.sort(
    (a, b) => a.created.getTime() - b.created.getTime()
  );
};

const downloadEpisodes = async (): Promise<void> => {
  const existingEpisodeIds = await getEpisodeIds();
  const episodes = await getSavedEpisodes();

  // Cleanup old data
  await Promise.all(
    existingEpisodeIds.map(async (episodeId) => {
      if (!episodes.find((e) => e.id === episodeId)) {
        await deleteEpisode(episodeId);
      }
    })
  );

  // Save new data
  const newEpisodes = episodes.filter(
    (e) => !existingEpisodeIds.includes(e.id)
  );
  for (let i = 0; i < newEpisodes.length; i++) {
    try {
      // One at a time...
      await saveEpisode(newEpisodes[i]);
    } catch(e) {
      // If it fails.. we tried.
      console.error("Failed to download episode", newEpisodes[i], e);
    }
  }
};

export {
  downloadEpisodes,
  getCurrentEpisode,
  getStoredEpisodes as getSavedEpisodes,
};
