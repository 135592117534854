import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { loginPath } from '../constants';
import LoadingState from '../constants/loading-state';
import { getAuthenticatedUser } from '../services/authentication';
import User from '../types/user';

function useAuthenticatedUser(): [User | null, LoadingState] {
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Loading
  );

  useEffect(() => {
    if (location.pathname === loginPath) {
      return;
    }

    getAuthenticatedUser()
      .then((u) => {
        setUser(u);
        setLoadingState(LoadingState.Success);
      })
      .catch((err) => {
        console.error('Failed to load authenticated user', err);
        setLoadingState(LoadingState.Error);
      });
  }, [location.pathname]);

  return [user, loadingState];
}

export default useAuthenticatedUser;
