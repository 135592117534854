import { Alert, CircularProgress } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import { loginPath } from '../constants';
import LoadingState from '../constants/loading-state';
import useAuthenticatedUser from '../hooks/useAuthenticatedUser';
import Home from './home';
import Login from './login';
import LoginRedirect from './login/redirect';

export default function Pages() {
  const location = useLocation();
  const [authenticatedUser, loadingState] = useAuthenticatedUser();

  if (location.pathname === loginPath) {
    // We're logging in, this page has its own loading logic.
    return (
      <Routes>
        <Route path={loginPath} element={<Login />} />
      </Routes>
    );
  }

  if (loadingState === LoadingState.Loading) {
    return <CircularProgress />;
  }

  if (loadingState === LoadingState.Error) {
    return (
      <Alert severity="error">
        Failed to load user data. Please refresh the page to try again.
      </Alert>
    );
  }

  if (!authenticatedUser) {
    // User is not logged in, to login flow.
    return <LoginRedirect />;
  }

  return (
    <Routes>
      <Route
        path={'/'}
        element={<Home authenticatedUser={authenticatedUser} />}
      />
    </Routes>
  );
}
