import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import PlayButton, { PlayButtonProperties } from './play-button';

type EpisodeCard = {} & PlayButtonProperties;

export default function EpisodeCard({
  episode,
  audio,
  currentEpisode,
  play,
}: EpisodeCard) {
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    setImage(episode.show.artwork[0].src);
  }, [episode]);

  return (
    <ListItem
      secondaryAction={
        episode.audio && (
          <PlayButton
            episode={episode}
            audio={audio}
            currentEpisode={currentEpisode}
            play={play}
          />
        )
      }
    >
      <ListItemAvatar>
        <Avatar src={image} />
      </ListItemAvatar>
      <ListItemText secondary={episode.created.toLocaleDateString()}>
        {episode.name}
      </ListItemText>
    </ListItem>
  );
}
