import { Alert, CircularProgress, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingState from '../../constants/loading-state';
import useQuery from '../../hooks/useQuery';
import { login } from '../../services/authentication';
import User from '../../types/user';

export default function Login() {
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Loading
  );
  const [user, setUser] = useState<User | null>();
  const [{ code }] = useQuery();

  useEffect(() => {
    if (!code) {
      window.location.href = `/login/spotify`;
      return;
    }

    login(code)
      .then(setUser)
      .catch((err) => {
        console.error('Failed to login.', err);
        setLoadingState(LoadingState.Error);
      });
  }, [code]);

  if (loadingState === LoadingState.Error) {
    return (
      <Alert severity="error">
        Login failed. Please <Link href="/login">try again</Link>.
      </Alert>
    );
  }

  if (user) {
    return <Navigate to="/" />;
  }

  return <CircularProgress />;
}
